import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import SubwayPage from "pages/SubwayPage";
import HomePage from "pages/Home";
import { SchedulePage } from "pages/Home/SchedulePage";
import { PolicyPage } from "pages";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <Routes>
        <Route path="/SubwayPage" element={<SubwayPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/Policy" element={<PolicyPage />} />
      </Routes>
    </RecoilRoot>
  );
}

export default App;
