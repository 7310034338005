import { SVGProps } from "assets/styleUtil";
import React from "react";

export default function SubwaySvg({ width = 23, ...props }: SVGProps) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 190 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="190" height="175" rx="50" fill="black" />
      <path
        d="M42.4792 33.5945C58.9195 23.1054 132.967 26.6779 146.231 33.5945C159.494 40.5111 172.784 95.703 159.494 104.919C146.205 114.136 42.4792 111.837 31.6778 104.919C20.8764 98.0022 26.0389 44.0835 42.4792 33.5945Z"
        fill="white"
      />
      <circle cx="143" cy="140" r="13" fill="white" />
      <circle cx="43" cy="140" r="13" fill="white" />
      <path
        d="M70.5227 45.4545H84.4545L96.2727 74.2727H96.8182L108.636 45.4545H122.568V92H111.614V63.4091H111.227L100.045 91.7045H93.0455L81.8636 63.25H81.4773V92H70.5227V45.4545Z"
        fill="black"
      />
      <path d="M95.5 153L148.761 204H42.2394L95.5 153Z" fill="black" />
    </svg>
  );
}
