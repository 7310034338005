export const white = "#FFFFFF";
export const black = "#16161D";
export const gray1 = "#222222";
export const gray2 = "#333333";
export const gray3 = "#4F4F4F";
export const gray4 = "#828282";
export const gray5 = "#BDBDBD";
export const gray6 = "#E0E0E0";
export const gray7 = "#F2F2F2";
export const gray8 = "#F9F9F9";
export const gray9 = "#F0F0F0";
export const mainBeige = "#F5F2EB";
export const mainGreen = "#04BA71";
export const mainNavy = "#04454D";
export const mainYellow = "#FDC827";
export const mainOrange = "#FF7A00";
export const mainMint = "#00B890";
export const mainDeepGreen = "#094F32";
export const secondMint = "#D6FFF6";

export type SVGProps = React.SVGProps<SVGSVGElement>;
