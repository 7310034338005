export function getParameterObject({ url }: { url: string }) {
  const params = url.split("?")[1]?.split("&") || [];
  const obj: { [key: string]: string } = {};

  for (let i = 0; i < params.length; i++) {
    const temp = params[i]?.split("=") || [];
    const key = temp[0];
    const value = temp.splice(1, temp.length).join("=");
    obj[key] = value;
  }
  return obj;
}
