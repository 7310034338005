import React, { useEffect } from "react";
import styled from "styled-components";
import { mainBeige } from "../assets/styleUtil";

interface Props {
  children: JSX.Element;
}

const Wrap = styled.div`
  display: flex;
  flex: 1;
  height: 100vh; // 100svh가 아닌 100vh가 올바른 값임을 가정
  justify-content: center;
  background: #f9f9f9;
  overflow: hidden;
`;

// Left 컴포넌트 생성
const Left = styled.div``;

// Center 컴포넌트 생성
const Center = styled.div`
  max-width: 480px;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  background: ${mainBeige};
`;

const CenterCont = styled.div`
  flex: 1;
  width: 100%;
  display: block;
  //   position: relative;
  //   overflow-x: hidden;
  //   overflow-y: scroll;
`;

export default function Wrapper({ children }: Props) {
  return (
    <Wrap>
      <Left />
      <Center>
        <CenterCont id="wrapper">{children}</CenterCont>
      </Center>
    </Wrap>
  );
}
