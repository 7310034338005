import { mainBeige } from "assets/styleUtil";
import BusSvg from "assets/svg/BusSvg";
import SubwaySvg from "assets/svg/SubwaySvg";
import { Header } from "components/Header";
import SubwayPage from "pages/SubwayPage";
import React, { useCallback, useEffect, useState } from "react";
import HomeBusPage from "./HomeBusPage";
import { SchedulePage } from "./SchedulePage";
import {
  SideBarPage,
  TJson,
  TMenuData,
  TMenuPropsData,
  TMenuPropsRe,
} from "./SideBarPage";

export default function HomePage() {
  const [status, setStatus] = useState<string>("BUS");
  const [selectedItemResult, setSelectedItemResult] =
    useState<TMenuPropsRe | null>(null);
  const changeStatus = (newStatus: string) => {
    setStatus(newStatus);
    localStorage.setItem("status", newStatus);
  };
  const [openBar, setOpenBar] = useState(false);

  useEffect(() => {
    const savedStatus = localStorage.getItem("status");
    if (savedStatus) {
      setStatus(savedStatus);
    }
  }, []);

  return (
    <div style={{ backgroundColor: mainBeige }}>
      {openBar && (
        <>
          <SideBarPage
            isOpen={openBar}
            setOpenBar={setOpenBar}
            setSelectedItemResult={setSelectedItemResult}
          />
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1,
            }}
            onClick={() => setOpenBar(false)}
          ></div>
        </>
      )}

      <Header
        setOpenBar={setOpenBar}
        stayHome={Boolean(selectedItemResult)}
        setSelectedItemResult={setSelectedItemResult}
      />
      {selectedItemResult ? (
        <SchedulePage selectedItemResult={selectedItemResult} />
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                width: "50px",
                marginRight: "10px",
              }}
              onClick={() => changeStatus(status === "BUS" ? "SUBWAY" : "BUS")}
            >
              {status === "BUS" ? (
                <SubwaySvg width={"40px"} />
              ) : (
                <BusSvg width={"35px"} />
              )}
            </div>
          </div>
          <>{status === "BUS" ? <HomeBusPage /> : <SubwayPage />}</>
        </>
      )}
    </div>
  );
}
