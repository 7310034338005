import React from "react";

export default function ArrowRight(props: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_27_1020)">
        <path
          d="M16.01 11H4V13H16.01V16L20 12L16.01 8V11Z"
          fill="black"
          fillOpacity="0.75"
        />
      </g>
      <defs>
        <clipPath id="clip0_27_1020">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
