import React from "react";

export default function ThinArrowSvg(props: any) {
  return (
    <svg
      width={props.width}
      height={props.width}
      viewBox="0 0 43 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5 22L8.81285 1.87048C5.68558 -0.679447 1 1.54577 1 5.58086C1 7.10203 1.72292 8.53267 2.94755 9.43503L20 22L2.55155 37.1526C1.566 38.0085 1 39.2496 1 40.5549C1 44.4288 5.56467 46.4971 8.47728 43.943L33.5 22Z"
        fill="#003362"
        stroke="#003362"
      />
    </svg>
  );
}
