import { gray2, gray3, gray6, gray8, gray9, white } from "assets/styleUtil";
import XbuttonSvg from "assets/svg/XbuttonSvg";
import React, { useEffect, useState } from "react";
import weekday1bus from "data/firstBusData/weekday_1bus.json";
import saturday1bus from "data/firstBusData/saturday_1bus.json";
import holidaySunday1bus from "data/firstBusData/holiday_sunday_1bus.json";

import weekday2bus from "data/secondBusData/weekday_2bus.json";
import holidaySaturday2bus from "data/secondBusData/saturday_holiday_2bus.json";
import sunday2bus from "data/secondBusData/sunday_2bus.json";
import styled from "styled-components";
import BlackTriangleSvg from "assets/svg/BlackTriangleSvg";
import { useNavigate } from "react-router-dom";
import ThinArrowSvg from "assets/svg/ThinArrowSvg";
import HomeSvg from "assets/svg/HomeSvg";

interface SideBarProps {
  isOpen: boolean;
}

const SidebarContainer = styled.div<SideBarProps>`
  background-color: ${(props) => props.theme.gray9};
  width: 200px;
  height: 100vh;
  padding: 10px 0 0 10px;
  position: fixed; // absolute가 아닌 fixed 사용
  top: 0;
  right: ${(props) =>
    props.isOpen ? "0" : "-200px"}; // isOpen에 따라 위치 변경
  transition: right 0.5s ease-in-out; // 부드러운 우측 이동을 위한 transition
  z-index: 100;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type TBusSchedule = {
  round: number;
  time: string;
};

export type TJson = {
  [key: string]: TBusSchedule[];
};

export type TMenuData = {
  title: string;
  result: TJson;
};

export type TMenuPropsData = {
  title: string;
  propsData: TMenuData;
};

export type TMenuPropsRe = {
  title: string;
  propsData: { title: string; result: TBusSchedule[] };
};

export type TMenu = {
  no: number;
  title: string;
  data: TMenuData[];
};

export function SideBarPage({
  isOpen,
  setOpenBar,
  setSelectedItemResult,
}: {
  isOpen: boolean;
  setOpenBar: (data: boolean) => void;
  setSelectedItemResult: (data: TMenuPropsRe | null) => void;
}) {
  const menu: TMenu[] = [
    {
      no: 1,
      title: "평일",
      data: [
        {
          title: "1번 버스",
          result: weekday1bus,
        },
        { title: "2번 버스", result: weekday2bus },
      ],
    },
    {
      no: 2,
      title: "토요일",
      data: [
        {
          title: "1번 버스",
          result: saturday1bus,
        },
        { title: "2번 버스", result: holidaySaturday2bus },
      ],
    },
    {
      no: 3,
      title: "일요일",
      data: [
        {
          title: "1번 버스",
          result: holidaySunday1bus,
        },
        { title: "2번 버스", result: sunday2bus },
      ],
    },
    {
      no: 4,
      title: "공휴일",
      data: [
        {
          title: "1번 버스",
          result: holidaySunday1bus,
        },
        { title: "2번 버스", result: holidaySaturday2bus },
      ],
    },
  ];
  const navigate = useNavigate();

  const [isClicked, setIsClicked] = useState(false);
  const [clickedNo, setClickedNo] = useState<number | null>(null);
  const handleMenuItemClick = (no: number) => {
    setClickedNo(clickedNo === no ? null : no);
  };
  const handleItemClick = (propsData: TMenuData, menuTitle: string) => {
    const itemResult: TMenuPropsRe = {
      title: menuTitle,
      propsData: {
        title: propsData.title,
        result: Object.values(propsData.result).flat() as TBusSchedule[],
      },
    };
    setSelectedItemResult(itemResult);
    setOpenBar(false);
  };
  return (
    <div
      style={{
        backgroundColor: gray9,
        width: "200px",
        height: "100vh",
        position: "absolute",
        zIndex: 100,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "10px",
        }}
      >
        <div
          style={{
            width: "28px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <div onClick={() => setOpenBar(false)}>
            <XbuttonSvg width={"18px"} />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        {menu.map((menuItem, index) => {
          const isMenuItemClicked = clickedNo === menuItem.no;

          return (
            <>
              <div
                key={index}
                style={{
                  marginTop: "15px",
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "space-between",
                  paddingRight: "20px",
                  borderRadius: isMenuItemClicked ? "10px" : 0,
                  backgroundColor: isMenuItemClicked ? gray6 : "transparent",
                }}
                onClick={() => handleMenuItemClick(menuItem.no)}
              >
                <Center
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginLeft: "10px",
                    marginTop: "5px",
                  }}
                >
                  {menuItem.title}
                </Center>
                <Center
                  style={{
                    transform: isMenuItemClicked
                      ? "rotate(90deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease", // Add a smooth transition
                  }}
                >
                  <ThinArrowSvg width="15px" />
                </Center>
              </div>
              <div style={{ marginLeft: "30px", marginTop: "5px" }}>
                {menuItem.no === clickedNo &&
                  menuItem.data.map((item, index) => {
                    return (
                      <div key={index} style={{ display: "flex" }}>
                        <Center style={{ marginTop: "10px" }}>
                          <BlackTriangleSvg width="9px" />
                        </Center>
                        <Center
                          style={{
                            marginLeft: "5px",
                            marginTop: "10px",
                            fontSize: "17px",
                            fontWeight: "600",
                            cursor: "pointer",
                            color: gray3,
                          }}
                          onClick={() => handleItemClick(item, menuItem.title)}
                        >
                          {item.title}
                        </Center>
                      </div>
                    );
                  })}
              </div>
            </>
          );
        })}
        <div
          style={{
            marginTop: "30px",
            width: "100%",
            height: "1px",
            backgroundColor: gray6,
          }}
        />
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            justifyContent: "space-between",
            paddingRight: "20px",
            marginLeft: "7px",
          }}
          onClick={() => {
            setSelectedItemResult(null), setOpenBar(false);
          }}
        >
          <Center
            style={{
              marginLeft: "5px",
              marginTop: "10px",
              fontSize: "17px",
              fontWeight: "800",
              cursor: "pointer",
              color: gray3,
            }}
          >
            홈으로 가기
          </Center>
          <div style={{ marginTop: "15px" }}>
            <HomeSvg width="25px" />
          </div>
        </div>
      </div>
    </div>
  );
}
