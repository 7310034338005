import { SVGProps } from "assets/styleUtil";
import React from "react";

export default function MenuSvg({ width = 23, ...props }: SVGProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="56"
        height="8"
        rx="4"
        transform="matrix(1 0 0 -1 0 8)"
        fill="#003362"
      />
      <rect y="31" width="56" height="8" rx="4" fill="#003362" />
      <rect y="15" width="56" height="8" rx="4" fill="#003362" />
    </svg>
  );
}
