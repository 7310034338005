import { atom } from "recoil";

export const firebaseNoticeBooleanAtom = atom({
  key: "firebaseNoticeBoolean",
  default: "",
});
export const firebaseNoticeAtom = atom({
  key: "firebaseNotice",
  default: "공지사항이 없습니다.",
});
