import { SVGProps } from "assets/styleUtil";
import React from "react";

export default function BusSvg({ width = 23, ...props }: SVGProps) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 171 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 33.0002C13 33.0002 72.5484 32.9998 84 33.0002C95.4516 33.0006 155 33.0002 155 33.0002V89.7778C155 89.7778 118.355 106 88.5807 106C58.8065 106 13 89.7778 13 89.7778V33.0002Z"
        fill="black"
      />
      <circle cx="137.5" cy="122.5" r="9.5" fill="black" />
      <path
        d="M44 122.5C44 127.747 39.7467 132 34.5 132C29.2533 132 25 127.747 25 122.5C25 117.253 29.2533 113 34.5 113C39.7467 113 44 117.253 44 122.5Z"
        fill="black"
      />
      <rect
        x="5"
        y="5"
        width="161"
        height="145"
        rx="15"
        stroke="black"
        strokeWidth="10"
      />
      <rect x="19" y="148" width="20" height="33" rx="10" fill="black" />
      <rect x="135" y="148" width="20" height="33" rx="10" fill="black" />
      <path
        d="M70.5227 91V44.4545H89.9318C93.4167 44.4545 96.3333 44.947 98.6818 45.9318C101.045 46.9167 102.818 48.2955 104 50.0682C105.197 51.8409 105.795 53.8939 105.795 56.2273C105.795 58 105.424 59.5833 104.682 60.9773C103.939 62.3561 102.917 63.5 101.614 64.4091C100.311 65.3182 98.803 65.9545 97.0909 66.3182V66.7727C98.9697 66.8636 100.705 67.3712 102.295 68.2955C103.902 69.2197 105.189 70.5076 106.159 72.1591C107.129 73.7955 107.614 75.7348 107.614 77.9773C107.614 80.4773 106.977 82.7121 105.705 84.6818C104.432 86.6364 102.591 88.1818 100.182 89.3182C97.7727 90.4394 94.8485 91 91.4091 91H70.5227ZM81.7727 81.9318H88.7273C91.1667 81.9318 92.9621 81.4697 94.1136 80.5455C95.2803 79.6212 95.8636 78.3333 95.8636 76.6818C95.8636 75.4848 95.5833 74.4545 95.0227 73.5909C94.4621 72.7121 93.6667 72.0379 92.6364 71.5682C91.6061 71.0833 90.3712 70.8409 88.9318 70.8409H81.7727V81.9318ZM81.7727 63.5909H88C89.2273 63.5909 90.3182 63.3864 91.2727 62.9773C92.2273 62.5682 92.9697 61.9773 93.5 61.2045C94.0455 60.4318 94.3182 59.5 94.3182 58.4091C94.3182 56.8485 93.7652 55.6212 92.6591 54.7273C91.553 53.8333 90.0606 53.3864 88.1818 53.3864H81.7727V63.5909Z"
        fill="white"
      />
    </svg>
  );
}
