import React from "react";

export default function HomeSvg({ width }: { width: string }) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 171 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M85.5 0L159.545 81L11.4548 81L85.5 0Z" fill="#003362" />
      <path
        d="M139 62H32V139H66.7824C66.7824 139 48.5069 89.1515 86.2369 89.1515C123.967 89.1515 104.512 139 104.512 139H139V62Z"
        fill="#003362"
        stroke="black"
      />
    </svg>
  );
}
