import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useState } from "react";
import weekday1bus from "data/firstBusData/weekday_1bus.json";
import saturday1bus from "data/firstBusData/saturday_1bus.json";
import holidaySunday1bus from "data/firstBusData/holiday_sunday_1bus.json";

import weekday2bus from "data/secondBusData/weekday_2bus.json";
import holidaySaturday2bus from "data/secondBusData/saturday_holiday_2bus.json";
import sunday2bus from "data/secondBusData/sunday_2bus.json";
import {
  black,
  gray3,
  gray4,
  gray5,
  mainBeige,
  mainDeepGreen,
  mainGreen,
  mainNavy,
  mainOrange,
  mainYellow,
  white,
} from "assets/styleUtil";
import ArrowRight from "assets/svg/ArrowRight";
import { Header } from "components/Header";
import SubwaySvg from "assets/svg/SubwaySvg";
import { link } from "fs";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";
import { getValue } from "firebase/remote-config";
import { fetchAndActivate } from "firebase/remote-config";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import busLottie from "../../assets/svg/busLottie.json";
import { useRecoilState } from "recoil";
import { firebaseNoticeAtom, firebaseNoticeBooleanAtom } from "atom/firebase";

type Tholiday = {
  dateName: string;
  locate: number;
};

type TLocdate = {
  dateKind: string;
  dateName: string;
  isHoliday: "Y" | "N";
  locdate: number;
  seq: number;
};
interface FirebaseValue {
  _value: string | boolean;
}
const year = moment().format("YYYY");
const month = moment().format("MM");
const today = moment().format("YYYYMMDD");

const key = process.env.REACT_APP_PUBLIC_HOLIDAY_KEY;

export const getHoliday = async (setIsHoliday: (data: boolean) => void) => {
  const url = `/B090041/openapi/service/SpcdeInfoService/getHoliDeInfo?ServiceKey=${key}&solYear=${year}&solMonth=${month}`;

  const response = await axios.get(
    window.location.hostname === "localhost"
      ? `http://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getHoliDeInfo?ServiceKey=${key}&solYear=${year}&solMonth=${month}`
      : "/holiday/" + url
  );

  if (response.status === 200) {
    const holidayData = response.data.response?.body?.items?.item;

    if (holidayData) {
      console.log("오늘은 공휴일인가요?", holidayData);
      const filteredHolidays = holidayData.filter(
        (data: any) => data.locdate?.toString() === today
      );
      if (filteredHolidays.length > 0) {
        setIsHoliday(true);
        console.log("공휴일이 맞습니다");
      } else {
        console.log("공휴일이 아닙니다");
      }
    }
  } else {
    console.log("error");
  }
};

export default function HomeBusPage() {
  const busnumberStore = localStorage.getItem("busState");

  const [busState, setBusState] = useState(Number(busnumberStore) || 1);
  const [currentTime, setCurrentTime] = useState(moment());
  const [currentBusTime, setCurrentBusTime] = useState("");
  const [minutesDifference, setMinutesDifference] = useState(0);
  const [nextBusTimes, setNextBusTimes] = useState<
    { time: string; spare?: boolean }[]
  >([]);
  const [isHoliday, setIsHoliday] = useState(false);
  const currentDate = moment();
  const currentDayOfWeek = currentDate.day(); // 0: 일요일, 1: 월요일, ..., 6: 토요일
  const firebaseConfig = {
    apiKey: "AIzaSyAs14FBiGpqd2AEiR_MzEE8gMYTPyyFC-c",
    authDomain: "villagebus-ad4ee.firebaseapp.com",
    projectId: "villagebus-ad4ee",
    storageBucket: "villagebus-ad4ee.appspot.com",
    messagingSenderId: "629792690777",
    appId: "1:629792690777:web:362c036e39228ea2b89748",
    measurementId: "G-GYQ1YESY5Y",
  };
  const [recoilNotice, setRecoilNotice] = useRecoilState(firebaseNoticeAtom);
  const [recoilNoticeBoolean, setRecoilNoticeBoolean] = useRecoilState(
    firebaseNoticeBooleanAtom
  );

  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 3600;

  const firebaseBus1 = getValue(
    remoteConfig,
    "bus1"
  ) as unknown as FirebaseValue;
  const firebaseBus2 = getValue(
    remoteConfig,
    "bus2"
  ) as unknown as FirebaseValue;
  const firebaseStatus = getValue(
    remoteConfig,
    "status"
  ) as unknown as FirebaseValue;
  const firebaseNoticeBoolean = getValue(
    remoteConfig,
    "noticeBoolean"
  ) as unknown as FirebaseValue;
  const firebaseNotice = getValue(
    remoteConfig,
    "notice"
  ) as unknown as FirebaseValue;

  useEffect(() => {
    setRecoilNoticeBoolean(firebaseNoticeBoolean._value as string);
    if (firebaseNoticeBoolean._value) {
      setRecoilNotice(firebaseNotice._value as string);
    }
  }, [firebaseNotice]);
  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        // ...
      })
      .catch((err) => {
        // ...
      });
  }, []);

  // 요일에 따라서 변수를 설정합니다.
  const isWeekday = currentDayOfWeek >= 1 && currentDayOfWeek <= 5; // 월요일부터 금요일까지
  const isSaturday = currentDayOfWeek === 6; // 토요일
  const isSundayOrHoliday = currentDayOfWeek === 0; // 일요일

  //공휴일데이터

  const handleBusClick = (busNumber: number) => {
    setBusState(busNumber);
    localStorage.setItem("busState", String(busNumber));
  };

  const getNextBusTimes = useCallback(
    (busState: number, currentTime: Moment | string) => {
      // 현재 시간 이후의 버스 시간을 필터링합니다.
      if (busState === 1) {
        if (isSundayOrHoliday || isHoliday) {
          setNextBusTimes(holidaySunday1bus.holiday_sunday_1bus);
        } else if (isWeekday) {
          setNextBusTimes(weekday1bus.weekday_1bus);
        } else if (isSaturday) {
          setNextBusTimes(saturday1bus.saturday_1bus);
        }
      } else {
        if (isSaturday || isHoliday) {
          setNextBusTimes(holidaySaturday2bus.weekend_holiday_2bus);
        } else if (isWeekday) {
          setNextBusTimes(weekday2bus.weekday_2bus);
        } else if (isSundayOrHoliday) {
          setNextBusTimes(sunday2bus.sunday_2bus);
        }
      }
    },
    [isSaturday, isSundayOrHoliday, isWeekday, isHoliday]
  );

  useEffect(() => {
    let closestTime = "";
    const currentTimeMomet = moment(currentTime).format("HH:mm");

    for (let i = 0; i < nextBusTimes.length; i++) {
      const busTime = moment(nextBusTimes[i].time, "HH:mm");
      if (busTime.format("HH:mm") == currentTimeMomet) {
        closestTime = nextBusTimes[i].time;
        break;
      } else if (busTime.isAfter(currentTime)) {
        closestTime = nextBusTimes[i].time;
        break;
      }
    }
    // 두 시간 사이의 차이 계산
    const timeDiff = moment.duration(
      moment(closestTime, "HH:mm").diff(currentTime)
    );
    const minutesDifference = Math.ceil(timeDiff.asMinutes()); // 정수로 분 차이 저장
    if (closestTime == currentTimeMomet) {
      setMinutesDifference(0);
      setCurrentBusTime(closestTime);
      getNextBusTimes(busState, currentTime);
    } else {
      setCurrentBusTime(closestTime);
      setMinutesDifference(minutesDifference);
      getNextBusTimes(busState, currentTime); // 초기 버스 상태에 따라 데이터 설정
    }
  }, [currentTime, busState, getNextBusTimes, nextBusTimes]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);

    return () => {
      clearInterval(intervalId); // 컴포넌트 언마운트 시 인터벌 정리
    };
  }, []);

  useEffect(() => {
    getHoliday(setIsHoliday);
  }, []);

  const BusIndicator = ({
    busNumber,
    isActive,
    handleBusClick,
    destination,
  }: {
    busNumber: number;
    isActive: boolean;
    handleBusClick: (data: number) => void;
    destination: string;
  }) => (
    <div
      style={{
        width: "50%",
        height: "50px",
        paddingTop: "5px",
        paddingBottom: "12px",
        backgroundColor: isActive ? mainYellow : white,
        cursor: "pointer",
        border: `1px solid ${gray5}`,
      }}
      onClick={() => handleBusClick(busNumber)}
    >
      <div
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {busNumber}번
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ fontSize: "15px", fontWeight: "600" }}>종점</div>
        <ArrowRight style={{ margin: "0 4px" }} />
        <div style={{ fontSize: "15px", fontWeight: "600" }}>{destination}</div>
      </div>
    </div>
  );

  const TimeDisplay = ({
    label,
    time,
    color,
  }: {
    label: string;
    time: string;
    color?: string;
  }) => (
    <div
      style={{
        width: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          color: white,
          fontSize: "15px",
          fontWeight: "700",
          marginTop: "5px",
        }}
      >
        {label}
      </div>
      <div
        style={{
          color: color || white,
          fontSize: "18px",
          fontWeight: "700",
        }}
      >
        {time}
      </div>
    </div>
  );

  const busStops = [
    { number: 1, destination: "과천역" },
    { number: 2, destination: "정부과천청사역" },
  ];

  const renderBusCurrentStatus = () => {
    if (
      (busState === 1 &&
        firebaseBus1._value === "true" &&
        firebaseStatus._value !== "") ||
      (busState === 2 &&
        firebaseBus2._value === "true" &&
        firebaseStatus._value !== "")
    ) {
      return (
        <div>
          <Player
            autoplay
            loop
            src={busLottie}
            style={{ height: "300px", width: "300px" }}
          ></Player>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "bolder",
              color: gray4,
            }}
          >
            {firebaseStatus._value}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ padding: "30px" }}>
          <div>
            <div
              style={{
                height: "60px",
                backgroundColor: mainGreen,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TimeDisplay
                label="현재 시간"
                time={currentTime.format("HH:mm")}
              />
              <TimeDisplay
                label="버스 출발 시간"
                time={nextBusTimes ? currentBusTime : "없음"}
                color="#BD4C25"
              />
            </div>
            <div
              style={{
                height: "50px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: currentBusTime
                  ? minutesDifference == 0
                    ? mainOrange
                    : minutesDifference >= 60
                    ? mainNavy
                    : gray3
                  : mainDeepGreen,
              }}
            >
              {currentBusTime ? (
                <div
                  style={{ fontSize: "22px", color: white, fontWeight: "bold" }}
                >
                  {minutesDifference == 0
                    ? "출발 또는 곧 출발"
                    : minutesDifference >= 60
                    ? nextBusTimes[0]?.time + "  첫 차"
                    : `${minutesDifference}분 후 출발예정`}
                </div>
              ) : (
                <div
                  style={{ fontSize: "22px", color: white, fontWeight: "bold" }}
                >
                  버스 운행 종료
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              marginLeft: "10px",
              marginTop: "10px",
            }}
          >
            <div style={{ color: gray3, fontSize: "15px" }}>
              * 출퇴근 시간대 버스는 유동적일 수 있습니다
            </div>
            <div style={{ color: gray3, fontSize: "15px", marginTop: "2px" }}>
              * 예비차는 유동적일 수 있습니다
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                maxHeight: window.innerHeight / 4,
                backgroundColor: "white",
                overflowY: "scroll",
              }}
            >
              {nextBusTimes
                .filter((busTime) =>
                  moment(busTime.time, "HH:mm").isSameOrAfter(currentTime)
                )
                .map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50px",
                      border: `0.5px solid ${gray5}`,
                    }}
                  >
                    <p
                      style={{
                        color: index === 0 ? mainOrange : black,
                        fontSize: "18px",
                      }}
                    >
                      {item.time} {item.spare && "(예비차)"}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: "20px",
        }}
      >
        {busStops.map((bus) => (
          <BusIndicator
            key={bus.number}
            busNumber={bus.number}
            isActive={busState === bus.number}
            handleBusClick={handleBusClick}
            destination={bus.destination}
          />
        ))}
      </div>
      {renderBusCurrentStatus()}
    </div>
  );
}
