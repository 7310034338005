import React from "react";

export function PolicyPage() {
  return (
    <div
      style={{
        padding: "10px 20px 50px 20px",
        overflow: "scroll",
        height: "100vh",
      }}
    >
      <h2>개인정보처리방침</h2>
      <p /> 마을버스 종점 시간표의 애플리케이션은 개인정보보호법에 따라
      이용자들의 개인정보 보호 및 권익을 보호하고자 다음과 같은 처리방침을 두고
      있습니다. 당사는 개인정보처리방침을 개정하는 경우 앱 화면 및 이 페이지를
      통하여 공지할 것입니다.
      <p />
      <h3>제1조(개인정보의 처리 목적)</h3> <p />
      본 애플리케이션은 개인정보를 수집하지 않는 독립 실행형 애플리케이션으로
      별도의 서버를 운영하거나 정보를 수집하지 않습니다. 당사는 구글플레이서비스
      기능을 이용하기 위한 기능과 광고서비스 등에서 특정 개인과 직접적인 관련이
      없는, 개인식별이 불가능한 정보를 수집할 수 있습니다. 당사에서 개인정보를
      별도로 저장하거나 이용하지 않습니다.
      <p />
      <h3>제2조(개인정보 파일 현황)</h3> <p />
      당사는 별도의 개인정보 파일을 사용하지 않으며 저장하지도 않습니다. 당사는
      쿠기를 저장하지 않으며 이용하지 않습니다. 단, 스토어 서비스가 이용하는
      정보 및 광고서비스가 이용하는 정보는 해당 서비스의 SDK를 거쳐 이용하게
      됩니다. 이는 당사에 저장되는 정보가 아닙니다. 이용자가 이에 대해 의문이
      있다면 해당 서비스(구글 및, 각 광고 미디어)로 직접 연락해야 합니다.
      <p />
      <h3>제3조(개인정보의 보유 및 이용기간)</h3> <p />
      당사는 개인정보를 직접적으로 저장하거나 보유하지 않습니다. 당사의
      애플리케이션은 모두 해당 미디어서비스(구글,광고미디어)의 SDK를 통해
      간접적으로 이용합니다. 따라서 당사는 이용자의 개인정보를 처리하는 내용도
      보유기간도 없습니다.
      <p />
      <h3>제4조 (개인정보의 제3자 제공에 관한 사항)</h3> <p />
      당사는 개인정보를 제3자에게 제공하지 않고 있습니다.
      <p />
      <h3>제5조 (개인정보처리 위탁)</h3> <p /> 당사는 개인정보를 위탁하고
      있지않습니다.
      <p />
      <h3> 제6조(정보주체의 권리, 의무 및 그 행사방법)</h3> <p />
      이용자는 개인정보주체로서 권리 행사할 수 있습니다. ● 개인정보 열람요구 ●
      오류 등이 있을 경우 정정 요구 ● 삭제요구 ● 처리 정지 요구 당사는
      개인정보를 저장하거나 위탁하지 않습니다.
      <p />
      <h3>제7조(개인정보의 파기)</h3> <p /> 당사의 애플리케이션은 독립 실행
      방식의 애플리케이션으로 별도의 서버를 사용하지 않고있습니다. 또한
      개인정보를 저장하지 않으므로 파기할 것이 없습니다. 그러나 사용자가 원할
      경우 애플리케이션을 [삭제]함으로서 모든 데이터를 파기할 수 있습니다.
      <h3>제9조(개인정보 보호책임자 작성)</h3>
      이메일 : lemone0509@gmail.com
    </div>
  );
}
