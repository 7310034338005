import React, { useEffect, useState } from "react";
import "moment/locale/ko";
import moment from "moment";
import MenuSvg from "assets/svg/MenuSvg";
import ReloadSvg from "assets/svg/ReloadSvg";
import { getParameterObject } from "utils/common";
import { useLocation } from "react-router-dom";
import HomeSvg from "assets/svg/HomeSvg";
import { TMenuPropsRe } from "pages/Home/SideBarPage";
import { getHoliday } from "pages/Home/HomeBusPage";
import { useRecoilValue } from "recoil";
import { firebaseNoticeAtom, firebaseNoticeBooleanAtom } from "atom/firebase";
import "./Header.css";

export function Header({
  setOpenBar,
  stayHome,
  setSelectedItemResult,
}: {
  setOpenBar: (data: boolean) => void;
  stayHome: boolean;
  setSelectedItemResult: (data: TMenuPropsRe | null) => void;
}) {
  const location = useLocation();
  const [currentStatus, setCurrentStatus] = useState("");
  const year = moment().format("YYYY");
  const month = moment().format("MM");
  const day = moment().format("DD");
  const dayOfWeek = moment().format("dd");
  const [isHoliday, setIsHoliday] = useState(false);
  const recoilNoticeValue = useRecoilValue(firebaseNoticeAtom);
  const recoilNoticeBooleanValue = useRecoilValue(firebaseNoticeBooleanAtom);

  const currentDate = moment();

  const currentDayOfWeek = currentDate.day(); // 0: 일요일, 1: 월요일, ..., 6: 토요일
  const isWeekday = currentDayOfWeek >= 1 && currentDayOfWeek <= 5; // 월요일부터 금요일까지
  const isSaturday = currentDayOfWeek === 6; // 토요일

  function getDayType(
    isHoliday: boolean,
    isWeekday: boolean,
    isSaturday: boolean
  ) {
    if (isHoliday) return "공휴일";
    if (isWeekday) return "평일";
    if (isSaturday) return "토요일";
    return "일요일";
  }

  useEffect(() => {
    const { status } = getParameterObject({ url: location.search });
    setCurrentStatus(status);
  }, []);

  useEffect(() => {
    getHoliday(setIsHoliday);
  }, []);

  return (
    <div>
      <div
        style={{
          padding: "20px 0 0 10px",
          width: "30px",
        }}
        onClick={() => setOpenBar(true)}
      >
        <MenuSvg />
      </div>
      {recoilNoticeBooleanValue === "true" && (
        <div
          style={{
            height: "20px",
            backgroundColor: "#fff",
            padding: "5px 00px ",
            margin: "0 10px",
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              fontWeight: "bolder",
              color: "orange",
              width: "70px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#fff",
              zIndex: 10,
            }}
          >
            📣 알림
          </div>
          <div
            style={{
              marginTop: "4px",
              marginLeft: "10px",
              fontSize: "15px",
              fontWeight: "400",
              animation: "textFlow 10s linear infinite",
            }}
          >
            {recoilNoticeValue}
          </div>
        </div>
      )}
      <div
        style={{
          marginTop: "10px",
          paddingBottom: "5px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ fontSize: "24px", fontWeight: 700 }}>
          {year}년 {parseInt(month)}월 {parseInt(day)}일({dayOfWeek})
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-5px",
            padding: "5px",
            cursor: "pointer",
          }}
          onClick={() =>
            !stayHome ? window.location.reload() : setSelectedItemResult(null)
          }
        >
          {!stayHome ? <ReloadSvg /> : <HomeSvg width="30px" />}
        </div>
      </div>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          fontWeight: "700",
          color: isHoliday ? "red" : "black",
        }}
      >
        {getDayType(isHoliday, isWeekday, isSaturday)}
      </div>
    </div>
  );
}
