import React from "react";

export default function BlackTriangleSvg(props: any) {
  return (
    <svg
      width={props.width}
      height={props.width}
      viewBox="0 0 43 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.5 29L0.499997 57.1458L0.5 0.854172L42.5 29Z"
        fill="#003362"
      />
    </svg>
  );
}
