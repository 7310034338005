import { gray3, gray5, mainYellow } from "assets/styleUtil";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { TMenuPropsRe } from "./SideBarPage";

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function SchedulePage({
  selectedItemResult,
}: {
  selectedItemResult: TMenuPropsRe;
}) {
  const result = selectedItemResult.propsData.result;

  return (
    <div>
      <Center>
        <div
          style={{
            width: "50%",
            height: "60px",
            marginTop: "15px",
            backgroundColor: mainYellow,
            border: `1px solid ${gray5}`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <span>{selectedItemResult.title}</span>
            <span> {selectedItemResult.propsData.title}</span>
          </div>
        </div>
      </Center>
      <Center>
        <div
          style={{
            marginLeft: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ color: gray3 }}>
            * 출퇴근 시간대 버스는 유동적일 수 있습니다
          </div>
          <div style={{ color: gray3, marginTop: "2px" }}>
            * 예비차는 유동적일 수 있습니다
          </div>
        </div>
      </Center>
      <Center>
        <div
          style={{
            width: "300px",
            maxHeight: "300px",
            marginTop: "17px",
            backgroundColor: "white",
            overflowY: "scroll",
          }}
        >
          {result.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50px",
                border: `0.5px solid ${gray5}`,
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                }}
              >
                {item.time}
              </p>
            </div>
          ))}
        </div>
      </Center>
    </div>
  );
}
