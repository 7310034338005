import { SVGProps } from "assets/styleUtil";
import React from "react";

export default function MenuSvg({ width = 23, ...props }: SVGProps) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 49 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="3.89342"
        y1="3.2381"
        x2="45.8934"
        y2="47.2381"
        stroke="#736F6F"
        strokeWidth="8"
      />
      <line
        x1="3.1385"
        y1="47.205"
        x2="45.1385"
        y2="4.20504"
        stroke="#736F6F"
        strokeWidth="8"
      />
    </svg>
  );
}
